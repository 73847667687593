import React, { createContext, useContext, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ReadingPhraseProvider,
  useLoadSnomedTerm,
  useLoadSnomedTermWithContext,
  useLoadReadingPhraseWithContext,
  useLoadSubTreeAttribute,
} from 'spinel';
import { ReadingPhrase, ReadingPhraseDraft, ReadingPhraseTree } from 'spinel/utils';
import { useCachedState } from './hooks/undoRedoHook';

interface ReadingPhraseEditorContextType {
  draft?: ReadingPhraseDraft;
  setDraft: React.Dispatch<React.SetStateAction<ReadingPhraseDraft | undefined>>;
  reset: (s?: ReadingPhraseDraft) => void;
}
export const ReadingPhraseEditorContext =
  createContext<ReadingPhraseEditorContextType>({
    setDraft: () => {
      return;
    },
    reset: () => {
      return;
    },
  });

/**
 * isExpired
 * undefined: 로그인 상태가 아님
 * false: 로그인 상태이고 만료되지 않음
 * true: 로그인 상태이고 만료됨
 */
interface SessionExpirationContextType {
  isExpired?: boolean;
  setIsExpired: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}
export const SessionExpirationContext =
  createContext<SessionExpirationContextType>({
    setIsExpired: () => {
      return;
    },
  });

interface LoadContextWrapperInformationType {
  conceptId?: string;
  setConceptId?: (conceptId?: string, contextConceptId?: string) => void;
  phraseContext?: string;
  setPhrase?: (contextId?: string) => void;
  setSubTree?: (tree: ReadingPhraseTree, subTreeRootId?: string) => void;
}
export const LoadContextWrapperInformation = createContext<LoadContextWrapperInformationType>({});
const LoadContextWrapperInformationProvider = (props: React.PropsWithChildren) => {
  const { children } = props;
  const [contextWrapperInfo, setContextWrapperInfo] = useState<{
    conceptId?: string;
    contextConceptId?: string;
    subTreeRootId?: string;
    subTree?: ReadingPhraseTree;
  }>({});
  const [phraseContext, setPhraseContext] = useState<string>();
  const { reset, draft } = useContext(ReadingPhraseEditorContext);
  const setConceptWithContext = useLoadSnomedTermWithContext(contextWrapperInfo.contextConceptId ?? '', true);
  const setPhraseWithContext = useLoadReadingPhraseWithContext(phraseContext ?? '', false);
  const setConcept = useLoadSnomedTerm(true);
  const loadSubTreeAttribute = useLoadSubTreeAttribute();

  const setConceptId = (id?: string, contextId?: string) => {
    reset();
    setContextWrapperInfo({ conceptId: id, contextConceptId: contextId });
  };

  const setPhrase = (contextId?: string) => {
    reset();
    setPhraseContext(contextId);
  };

  const setSubTree = (tree: ReadingPhraseTree, subTreeRootId?: string) => {
    setContextWrapperInfo({ subTree: tree, subTreeRootId });
  };

  // load concept
  useEffect(() => {
    if (!draft && contextWrapperInfo.conceptId?.length) {
      if (contextWrapperInfo.contextConceptId?.length) {
        setConceptWithContext(contextWrapperInfo.conceptId);
      } else {
        setConcept(contextWrapperInfo.conceptId);
      }
    }
  }, [draft, contextWrapperInfo]);

  // load phrase
  useEffect(() => {
    const subtree = contextWrapperInfo.subTree;
    const subTreeRootId = contextWrapperInfo.subTreeRootId;
    if (!draft && subtree) {
      if (phraseContext?.length) {
        setPhraseWithContext(subtree, subTreeRootId);
      } else {
        loadSubTreeAttribute(subtree, subTreeRootId);
      }
    }
  }, [draft, phraseContext]);

  return (
    <LoadContextWrapperInformation.Provider
      value={{
        conceptId: contextWrapperInfo.conceptId,
        setConceptId,
        phraseContext,
        setPhrase,
        setSubTree,
      }}
    >
      {children}
    </LoadContextWrapperInformation.Provider>
  );
};

interface ReadingPhraseEditorProviderProps extends React.PropsWithChildren {
  client: QueryClient;
}
export const ReadingPhraseEditorProvider = (props: ReadingPhraseEditorProviderProps) => {
  const { client, children } = props;

  const {
    state: draft,
    setState: setDraft,
    onCommit,
    undo,
    redo,
    reset,
  } = useCachedState<ReadingPhraseDraft>();
  const onCommitPhrase = (phrase: ReadingPhrase) => {
    onCommit({ ...draft, ...phrase });
  };

  const undoRedoCallback = (event: KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
      event.preventDefault();
      undo();
    }
    if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
      event.preventDefault();
      redo();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', undoRedoCallback);
    return () => document.removeEventListener('keydown', undoRedoCallback);
  }, []);

  const [isExpired, setIsExpired] = useState<boolean | undefined>(undefined);
  return (
    <QueryClientProvider client={client}>
      <ReadingPhraseEditorContext.Provider
        value={{ draft, setDraft, reset }}
      >
        <ReadingPhraseProvider
          value={draft}
          onChange={(p) => setDraft({ ...draft, ...p })}
          onCommit={onCommitPhrase}
        >
          <SessionExpirationContext.Provider
            value={{ isExpired, setIsExpired }}
          >
            <LoadContextWrapperInformationProvider>
              {children}
            </LoadContextWrapperInformationProvider>
          </SessionExpirationContext.Provider>
        </ReadingPhraseProvider>
      </ReadingPhraseEditorContext.Provider>
    </QueryClientProvider>
  );
};
