import React from 'react';
import { ReadingPhraseTree } from 'snowphrase-api';
import { isSctConcept, isSctConceptNode } from 'spinel/utils';

export const getCodeValueByNodeId = (
  tree?: ReadingPhraseTree,
  nodeId?: string,
) => {
  const node = tree?.nodeMap[nodeId ?? ''];
  if (node && isSctConceptNode(node)) {
    if (node.range && isSctConcept(node.range)) {
      return node.range.codeValue;
    } else if (isSctConcept(node.conceptValue)) {
      return node.conceptValue.codeValue;
    }
  }
};
