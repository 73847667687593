import React, { useState, useEffect } from 'react';
import { ReadingPhraseSearch } from 'spinel';
import { SavedReadingPhrase } from 'spinel/utils';
import { useUser } from '../../hooks/authHook';
import { useLoadReadingPhrase } from '../../hooks/readingPhraseHook';
import { useDeleteReadingPhrase } from '../../hooks/readingPhrase';
import { Modal, ModalAction, ModalContent } from '../Modal';
import './load-reading-phrase-modal.scss';

interface LoadReadingPhraseModalProps {
  open: boolean;
  onDismiss: () => void;
}

export const LoadReadingPhraseModal = (props: LoadReadingPhraseModalProps) => {
  const { open, onDismiss } = props;
  const getUsername = useUser();
  const [selected, setSelected] = useState<SavedReadingPhrase | undefined>();
  const setPhraseId = useLoadReadingPhrase();
  const { mutate } = useDeleteReadingPhrase();
  const [username, setUsername] = useState<string | undefined>('');

  useEffect(() => {
    setUsername(getUsername()?.email);
  }, [getUsername()?.email]);

  useEffect(() => {
    if (!open) {
      setSelected(undefined);
    }
  }, [open]);

  const onDelete = () => {
    if (selected) {
      mutate(selected.phraseId);
    }
  };

  const onClick = () => {
    if (selected) {
      setPhraseId(selected.phraseId);
      onDismiss();
    }
  };

  const onDoubleClickItem = (item?: SavedReadingPhrase) => {
    if (item?.phraseId) {
      setPhraseId(item?.phraseId);
      onDismiss();
    }
  };

  return (
    <Modal title='Load Reading Phrase' open={open} onDismiss={onDismiss}>
      <ModalContent>
        <ReadingPhraseSearch onClick={setSelected} onDoubleClick={onDoubleClickItem} showSelected />
      </ModalContent>
      <ModalAction>
        <button
          className='load-reading-phrase-modal__confirm-button'
          disabled={!selected}
          onClick={onClick}
        >
          Confirm
        </button>
        <button
          className='load-reading-phrase-modal__delete-button'
          disabled={(username === undefined || username !== selected?.user.email) || !selected}
          onClick={onDelete}
        >
          Delete
        </button>
      </ModalAction>
    </Modal>
  );
};
