import React from 'react';
import { ReadingPhrase } from 'spinel/utils';
import './save-reading-phrase-modal.scss';

interface ReadingPhraseViewerProps {
  phrase: ReadingPhrase;
}
export const ReadingPhraseViewer = (props: ReadingPhraseViewerProps) => {
  const { phrase } = props;
  return (
    <div className='reading-phrase-viewer'>
      <div className='reading-phrase-viewer__field reading-phrase-viewer__field-title'>
        <span className='reading-phrase-viewer__field-label'>Title:</span>
        {phrase.title}
        <span className='reading-phrase-viewer__governance'>
          {phrase.public ? 'Public' : 'Private'}
        </span>
      </div>
      <div className='reading-phrase-viewer__field'>
        <span className='reading-phrase-viewer__field-label'>Type:</span>
        {phrase.type ? phrase.type.codeMeaning : 'All'}
      </div>
      {phrase.tags.map(tag => (
        <div key={tag.key + tag.value} className='reading-phrase-viewer__field'>
          <span className='reading-phrase-viewer__field-label'>{tag.key}: </span>
          {tag.value}
        </div>
      ))}
    </div>
  );
};
