import React, { useEffect, useState } from 'react';
import { Modal, ModalAction, ModalContent, ValueSetList } from 'spinel';
import { ValueSet } from 'spinel/utils';
import './change-public-confirm-modal.scss'

interface ChangePublicConfirmModalProps {
  valuesets: ValueSet[];
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}
export const ChangePublicConfirmModal = (props: ChangePublicConfirmModalProps) => {
  const { valuesets, open, onDismiss, onConfirm } = props;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [open]);

  return (
    <Modal
      small
      title='Change Private Value Sets to Public'
      open={open}
      onDismiss={onDismiss}
    >
      <ModalContent>
        <div className='change-public-confirm-modal__warning-message'>
          To save this phrase as public, below valuesets should be public
        </div>
        <ValueSetList items={valuesets} />
      </ModalContent>
      <ModalAction>
        {!!errorMessage.length && (
          <div className='change-public-confirm-modal__error-message'>
            {errorMessage}
          </div>
        )}
        <button
          className='change-public-confirm-modal__button'
          onClick={onConfirm}
        >
          Confirm and Save
        </button>
        <button
          className='change-public-confirm-modal__button'
          onClick={onDismiss}
        >
          Cancel
        </button>
      </ModalAction>
    </Modal>
  );
};