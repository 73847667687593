import React from 'react';
import { QueryClient } from 'react-query';
import { SnowPhraseCredentialProvider, ApiClientContextProvider } from 'spinel';
import { useSpinellaToken, useUser } from './hooks/authHook';
import { MainPage } from './pages/ReadingPhraseCreator/MainPage';
import { ReadingPhraseEditorProvider } from './ReadingPhraseEditorProvider';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  const getSpinellaToken = useSpinellaToken();
  const getUser = useUser();
  return (
    <ApiClientContextProvider baseURL={process.env.REACT_APP_BACKEND_URL}>
      <ReadingPhraseEditorProvider client={client}>
        <SnowPhraseCredentialProvider getCredentials={getSpinellaToken} getUserInfo={getUser}>
          <MainPage />
        </SnowPhraseCredentialProvider>
      </ReadingPhraseEditorProvider>
    </ApiClientContextProvider>
  );
}

export default App;
