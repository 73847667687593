import { AxiosError, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { useCallback, useContext } from 'react';
import { UseMutationOptions, useQueryClient } from 'react-query';
import { SignedInUser, SignInRequest, SignInResponse } from '../api/auth';
import { getSpinellaToken } from '../utils';
import { useRequestSignIn } from './auth';
import { READING_PHRASE } from './readingPhrase';
import { ReadingPhraseEditorContext, SessionExpirationContext } from '../ReadingPhraseEditorProvider';
import { initialTree } from 'spinel/utils';

const AUTH = 'AUTH';
const USER = 'USER';

export const useAuth = () => {
  return useCallback(() => {
    const token = localStorage.getItem(AUTH);
    if (token) {
      const { exp } = jwtDecode<{ exp: number }>(token);
      if (exp < Date.now() / 1000) {
        localStorage.removeItem(AUTH);
        localStorage.removeItem(USER);
        return undefined;
      }
      return token;
    }
    return undefined;
  }, []);
};

export const useUser = () => {
  return useCallback(() => {
    const token = localStorage.getItem(AUTH);
    if (token) {
      const { exp, user } = jwtDecode<{ exp: Number, user: string}>(token);
      const userObj = JSON.parse(user) as SignedInUser;
      if (exp < Date.now() / 1000) {
        localStorage.removeItem(AUTH);
        localStorage.removeItem(USER);
        return undefined;
      }
      return { systemId: 'hpacs', userId: userObj.id, email: userObj.email };
    }
    return undefined;
  }, []);
};

export const useSignIn = (
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<SignInResponse, any>,
      AxiosError,
      SignInRequest,
      unknown
    >,
    'mutationFn'
  >
): ((params: SignInRequest) => void) => {
  const queryClient = useQueryClient();
  const { setIsExpired } = useContext(SessionExpirationContext);
  const { mutate: signIn } = useRequestSignIn({
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
      localStorage.setItem(AUTH, params[0].data.jwt);
      localStorage.setItem(USER, JSON.stringify(params[0].data.user));
      setIsExpired(false);
      queryClient.invalidateQueries(READING_PHRASE);
    },
  });
  return signIn;
};

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const { setIsExpired } = useContext(SessionExpirationContext);

  return useCallback(() => {
    localStorage.removeItem(AUTH);
    localStorage.removeItem(USER);
    queryClient.invalidateQueries(READING_PHRASE);
    setIsExpired(undefined);
  }, [setIsExpired]);
};

export const useSpinellaToken = (onSessionExpired?: () => void): () => string | undefined => {
  const getAuth = useAuth();
  const { isExpired } = useContext(SessionExpirationContext);

  return useCallback(() => {
    const auth = getAuth();
    if (auth === undefined) {
      if (isExpired !== undefined) {
        onSessionExpired?.();
      }
      return;
    }

    const userString = localStorage.getItem(USER);
    if (userString === null) {
      return;
    }
    const user = JSON.parse(userString) as SignedInUser;
    return getSpinellaToken('hpacs', user.id, user.email);
  }, [getAuth]);
};

export const useSpinellaTokenWithExpirationHandler = () => {
  const { setDraft } = useContext(ReadingPhraseEditorContext);
  const { setIsExpired } = useContext(SessionExpirationContext);

  const getSpinellaToken = useSpinellaToken(() => {
    localStorage.removeItem(AUTH);
    localStorage.removeItem(USER);
    setDraft((prev) =>
      prev
      ? {
        ...prev,
        readingPhraseTree: initialTree()
      }
      : undefined
    );
    setIsExpired(true);
  });

  return getSpinellaToken;
};
