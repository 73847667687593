import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SctConceptSearch } from 'spinel';
import { CodedEntry } from 'spinel/utils';
import { situationWithExplicitContext, useContextWrapperEcls, useSnomedChildren } from '../../hooks/snowstorm';
import { LoadContextWrapperInformation } from '../../ReadingPhraseEditorProvider';
import { Modal, ModalContent, ModalAction } from '../Modal';
import './select-root-concept-modal.scss';

interface SelectRootConceptModalProps {
  open: boolean;
  onDismiss: () => void;
}
export const SelectRootConceptModal = (props: SelectRootConceptModalProps) => {
  const { open, onDismiss } = props;
  const [selectedConcept, setSelectedConcept] = useState('');
  const { setConceptId } = useContext(LoadContextWrapperInformation);

  const { data: contexts } = useSnomedChildren(situationWithExplicitContext);
  const filteredContexts = useMemo(
    () => contexts?.filter((context) => context.descendantCount !== 0) ?? [],
    [contexts]
  );
  const [contextConceptId, setContextConceptId] = useState('');
  const { data: ecls } = useContextWrapperEcls(contextConceptId);

  const onSetRootConcept = (newRootConceptId: string) => {
    setConceptId?.(newRootConceptId, contextConceptId);
    onDismiss();
  };

  const onDoubleClickItem = (item?: CodedEntry) => {
    if (item?.codeValue) {
      onSetRootConcept(item.codeValue);
    }
  };

  useEffect(() => {
    setSelectedConcept('');
  }, [open]);

  return (
    <Modal title='New Reading Phrase' open={open} onDismiss={onDismiss}>
      <ModalContent>
        <div className='select-root-concept-modal'>
          <select onChange={(e) => setContextConceptId(e.target.value)}>
            <option value={''}>No Context</option>
            {filteredContexts.map((context) => (
              <option key={context.conceptId} value={context.conceptId}>
                {context.pt.term}
              </option>
            ))}
          </select>
          <SctConceptSearch
            onClickItem={(item) => {
              setSelectedConcept(item?.codeValue ?? '');
            }}
            onDoubleClickItem={onDoubleClickItem}
            ecl={contextConceptId && ecls && ecls.length !== 0 ? ecls.join(' OR ') : undefined}
          />
        </div>
      </ModalContent>
      <ModalAction>
        <button
          className='select-root-concept-modal__confirm-button'
          disabled={!selectedConcept.length}
          onClick={() => onSetRootConcept(selectedConcept)}
        >
          Confirm
        </button>
      </ModalAction>
    </Modal>
  );
};
